import React, {useEffect} from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  useTheme, Menu,
  Flex,
  useMediaQuery,
  Button
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Content = () => {
  const { t } = useTranslation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  return (
    <Box
      textAlign="center"
      position="relative"
      width="100%"
      mt={isMobileView ? 5:10}
      pt={isMobileView ? 5:10}
      // bgImage="url('slide.svg')" 
      opacity={1.0}
    >
    
      <Box
        pl={"10%"}
        height="auto"
        width="90%"
        backgroundColor={"transparent"}
      // mt={"-10vh"}
      >
        <Text
          textAlign="left"
          fontSize="xl"
          color="gray.700"
          mb={4}
        >
          {t("home.homeDescription")}
        </Text>
      </Box>
      <Box
        pl={"10%"}
        height="auto"
        width="90%"
        backgroundColor={"transparent"}
        id="selectivemutism"
      >
      </Box>
    </Box>
  );
};

export default Content;
