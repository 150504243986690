import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  InputGroup,
  useMediaQuery,
  Select,
  Textarea,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  ModalFooter,useTheme
} from "@chakra-ui/react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { environment } from '../../environments';
import { MultiSelect } from 'chakra-multiselect'

const JoinCommunity = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [study, setStudy] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [checkedItem, setCheckedItem] = useState([false]);
  const { colors } = useTheme();

  const [isMobileView] = useMediaQuery("(max-width: 991px)");
  const showToast = (description, status) => {
    toast({
      description: description,
      status: status,
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  };
  const handleJoinClick = async () => {
    if (
      email == "" ||
      name == "" ||
      study == "" ||
      study === t("join_study.select_study")
    ) {
      showToast(`${t("join_study.input_warning")}`, "warning");
      return;
    }

    if (checkedItem === false) {
      showToast(`${t("join_study.policy_check_warning")}`, "warning");
      return;
    }

    setSubmitting(true)
    const data = {
      name: name,
      email: email,
      phone: phone,
      study: study,
      description: description,
    };
    try {
      const response = await axios.post(
        `${environment.BACKEND_API}/subscribe`,
        data
      );

      if (response.status === 200) {
        showToast(`${t("join_study.email_sent")}`, "success");
      } else {
        showToast(`${t("join_study.error_submiting_email")}`, "success");
      }
      resetState()
      onClose()

    } catch (error) {
      console.log(error);
      showToast(`${t("join_study.error_submiting_email")}`, "error");
      setSubmitting(false)

    }
  };
 const resetState = () => {
    setSubmitting(false)
    setName("")
    setEmail("")
    setPhone("")
    setDescription("")
    setStudy("")
 }
  const closeModal = () =>{
      resetState()
      onClose()
  }

  const handleNavigate = () =>{
    closeModal()
    navigate("/privacy-policy")
  }
  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader color={"gray.600"}>{t("join_study.joinInTitle")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            id="heroSection"
            justifyContent="center"
            flexDirection={"row"}
            alignItems="center"
            borderRadius={isMobileView ? "md" : "full"}
          >
            <InputGroup display={'block'}>
            <Text mt='8px'>{t('join_study.nameLabel')}</Text>
              <Input
                isRequired
                size={isMobileView ? "md" : "md"}
                // placeholder={t("join_study.name")+ " *"}
                borderRadius="full"
                bg="white"
                _placeholder={{ color: "gray.500" }}
                w={"100%"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                mt={2}
                type="text"
                color={'#087c99'}
              />
              
            </InputGroup>
            <InputGroup display={'block'}>
            <Text mt='8px'>{t('join_study.emailLabel')}</Text>
              <Input
                isRequired
                size={isMobileView ? "md" : "md"}
                borderRadius="full"
                // placeholder={t("join_study.address")+ " *"}
                bg="white"
                _placeholder={{ color: "gray.500" }}
                w={"100%"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                mt={2}
                color={'#087c99'}
              />
            </InputGroup>
            <InputGroup display={'block'}>
            <Text mt='8px'>{t('join_study.phoneLabel')}</Text>
              <Input
                size={isMobileView ? "md" : "md"}
                borderRadius="full"
                w={"100%"}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="number"
                // placeholder={t("join_study.phone_number")+" (Optional)"}
                mt={2}
                bg="white"
                color={'#087c99'}
              />
            </InputGroup>
            <InputGroup display={'block'}>
            <Text mt='8px'>{t('join_study.studyOptionLabel')}</Text>
            <Select
              isRequired
              size={isMobileView ? "md" : "md"}
              bg="white"
              borderRadius="full"
              // placeholder={t("join_study.select_study")+" *"}
              mt={2}
              _placeholder={{
                color: "red",
              }}
              color={study === t("join_study.select_study") || study === "" ? 'gray.500' : "#087c99" }
              onChange={(e) => setStudy(e.target.value)}
              
            >
              {t("study", { returnObjects: true }).map((stud) => (
                <option key={stud} value={stud}>
                  {stud}
                </option>
              ))}
            </Select>

            {/* <MultiSelect
              options={t("study", { returnObjects: true })}
              value={study}
              label='Choose an item'
              onChange={setStudy}
            /> */}
            </InputGroup>

            <Text mt='8px'>{t('join_study.descriptionLabel')}</Text>
            <Textarea
              w={"100%"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              // placeholder={t("join_study.description")}
              bg="white"
              mt={2}
              fontSize={"lg"}
              borderRadius={10}
            />
            <Box
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                display: "flex",
              }}
              direction="row"
            >
              <Checkbox
                onChange={(e) => {
                  setCheckedItem(e.target.checked)
                }}
                mt={10}
                colorScheme="cyan"
              >
                <Box
                  alignItems={"center"}
                  flexDirection={"row"}
                >
                  <Text onClick={() => handleNavigate()}>
                  {t("join_study.privacy_policy")} <b ml={2} style={{color: '#3bc1e3'}} fontWeight={"bold"}>{t("join_study.policy")}</b> {t('join_study.dePrivacyExt')}
                  </Text>
                </Box>
              </Checkbox>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
        <Button
            borderWidth={1}
            borderColor={"gray.300"}
            bg="none"
            color={"gray.600"}
            onClick={onClose}
            isDisabled={submitting}
          >
            {t("join_study.cancel")}
          </Button>
          <Button
            size={"md"}
            // mt={isMobileView ? -2 : 2}
            borderBottomLeftRadius="full"
            colorScheme="primary.600"
            borderBottomRightRadius="full"
            borderTopRightRadius="full"
            onClick={handleJoinClick}
            isDisabled={submitting}
            pl={7}
            pr={7}
            ml={5}
            color={"gray.700"}
          >
            {submitting == true ? t("join_study.joining") + "..." : t("join_study.join")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default JoinCommunity;
