import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  useMediaQuery,
  useTheme
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");
  const { colors } = useTheme();
  const [language, setLanguage] = useState('');

  const getInitialLanguage = () => {
    const savedLang = localStorage.getItem("appLanguage") || "en";
    const language =  savedLang === "en" ? "English" : "German";
    setLanguage(language)
    changeLanguage(savedLang, language);

  };
  // Set the initial language when the component mounts
  useEffect(() => {
    getInitialLanguage()
  }, [i18n, language]);

  const changeLanguage = (lang, languageName) => {
    i18n.changeLanguage(lang);
    setLanguage(languageName);
    localStorage.setItem("appLanguage", lang);
  };

  return (
    <Menu>
      <MenuButton
        size={isMobileView ? "sm" : "lg"}
        variant={isMobileView ? "outline" : "solid"}
        bg={isMobileView ? "transparent" : "white"}
        as={Button}
        rightIcon={<ChevronDownIcon color={"primary.300"} />}
        _hover={{ color: "white" }}
      >
        <Text color={"primary.300"}>
          {language}{" "}
          {language === "English"
            ? "🏴"
            : language === "German"
            ? "🇩🇪"
            : "🇦🇪"}
        </Text>
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => changeLanguage("en", "English")}
          bg={language === "English" ? colors.primary[300] : "white"}
        >
          🇦🇪 English
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage("de", "German")}
          bg={language === "German" ? colors.primary[300] : "white"}
        >
          🇩🇪 German
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default LanguageSwitch;
