export const pl = {
  "navbar": {
    "home": "Startseite",
    "selective_mutism": "Selektiver Mutismus",
    "autism": "Autismus",
    "aboutus": "Über uns",
    "kidssmass": "KIDS-SM-ASS",
    "contactUs": "Kontakt",
    "join": "Join",
    "logo": [
      "https://mappy.s3.eu-central-1.amazonaws.com/images/vitlogo.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/logopartner.png"
    ]
  },
 "home":{
  "welcomeMessage": "Welcome to the KIDS-SM-ASS project website!",
  "welcomeMessageDescription": "AI-assisted differential diagnosis for selective mutism and autism spectrum disorder.",
  "homeDescription": "Welcome to the KIDS-SM-ASS project website! KIDS-SM-ASS stands for AI-assisted differential diagnosis for selective mutism and autism spectrum disorder. Learn about selective mutism (SM) and autism spectrum disorders (ASD), and discover how artificial intelligence supports early detection. Explore how you can help us better support children with autism or selective mutism.",
  "banner_images":[
      "https://mappy.s3.eu-central-1.amazonaws.com/images/bg.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/child.svg",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/partners.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/ground.png"
    ]
  },
  "selectiveMutism": {
    "title": "Selektiver Mutismus",
    "question": "Was ist selektiver Mutismus?",
    "description": [
      {
        "descr": "Von selektivem Mutismus betroffen sind Kinder, die in bestimmten, vorhersehbaren Situationen oder gegenüber bestimmten Personen nicht sprechen, während das Sprechen in anderen Situationen und insbesondere gegenüber vertrauten Personen problemlos möglich ist."
      },
      {
        "descr": "„Selektiver Mutismus“ zählt zu den Angststörungen und tritt bei ca. 1% aller Kinder auf, wobei die Dunkelziffer wahrscheinlich noch höher liegt. Fälschlicherweise wird häufig angenommen, dass das Kind „nur etwas schüchtern“ sei, und ratsuchende Eltern werden mit den Worten „das wächst sich schon aus“ zurückgewiesen. Während schüchterne Kinder in sozialen Situationen nach einer gewissen Aufwärmphase in der Regel zu sprechen beginnen und dabei zunehmend lockerer werden, hat das Nicht-Sprechen der Kinder mit selektivem Mutismus eine hohe zeitliche Stabilität."
      },
      {
        "descr": "Die Symptomatik selektiv mutistischer Kinder kann sich in unterschiedlichen Ausprägungen äußern. So zeigen sich manche betroffenen Kinder in angstauslösenden Situationen „wie erstarrt“. Andere hingegen antworten auf Fragen vielleicht nur mimisch-gestisch, mit kurzen knappen Äußerungen, sehr leiser Stimme oder flüsternd."
      },
      {
        "descr": "Selektiver Mutismus kann erhebliche Beeinträchtigungen im Leben der Kinder verursachen, die sich aufgrund ihrer Angst nicht so verhalten können, wie sie es gerne würden. Dies hat sowohl Auswirkungen auf die schulische Leistung als auch auf die Kommunikation mit Gleichaltrigen oder den Kindergärtner:innen/Lehrer:innen. Um festzustellen, ob bei einem Kind ein selektiver Mutismus vorliegt, ist eine ausführliche psychologische Diagnostik anzuraten."
      },
      {
        "descr": "Selektiver Mutismus ist eine Störung, die in der Regel zwischen 2 und 5 Jahren beginnt. Laut aktuellen Studien entsteht selektiver Mutismus aufgrund einer Kombination mehrerer Einflussfaktoren, die bei verschiedenen Kindern aber jeweils eine unterschiedlich große Rolle spielen können. Manche Kinder neigen schon im Säuglingsalter zu ängstlichem bzw. zurückhaltendem Verhalten und reagieren auf neue, ungewohnte Reize mit Anspannung und Vermeidung. Zeigt ein Kind solch ein ängstliches Temperament, erhöht dies die Wahrscheinlichkeit für einen späteren selektiven Mutismus. Studien konnten zeigen, dass ein genetischer Einfluss bei der Entstehung des selektiven Mutismus besteht. Außerdem scheint das Vorliegen eines Migrationshintergrunds, ein Risikofaktor für die Entstehung dieser Erkrankung zu sein. Hierbei spielt vor allem das Lernen einer neuen Sprache und die damit einhergehende Unsicherheit beim Sprechen eine Rolle."
      },
      {
        "descr": "Teilweise kursieren falsche Annahmen zu Entstehungsfaktoren, welche aber aufgrund des aktuellen Forschungsstands nicht bestätigt werden können: Selektiver Mutismus entsteht nicht durch ein traumatisches Erlebnis bzw. eine Traumatisierung des Kindes."
      },
      {
        "descr": "Bei selektivem Mutismus handelt es sich in aller Regel nicht um eine Trotzreaktion oder Verweigerung des Sprechens. Vielmehr können sich die betroffenen Kinder aufgrund ihrer intensiven Angst nicht äußern, obwohl sie gerne sprechen würden."
      }
    ]
  },
  "autism": {
    "title": "Autismus",
    "question": "Was ist Autismus?",
    "description": [
      {
        "descr": "Die Autismus-Spektrum-Störung ist gekennzeichnet durch Schwierigkeiten in der Kommunikation und im sozialen Miteinander sowie Verhaltensweisen, die durch wiederkehrende Muster und Wiederholung geprägt sind. Autismus gehört zu den Entwicklungsstörungen und betrifft circa eine von hundert Personen. Dabei kann sich Autismus jedoch sehr unterschiedlich zeigen. Manche Menschen mit Autismus brauchen zum Beispiel mehr Unterstützung im Alltag als andere und während manche sehr gute Sprachkenntnisse haben, können andere nicht oder nur eingeschränkt sprechen."
      },
      {
        "descr": "Menschen mit Autismus haben häufig Schwierigkeiten, Sprache, Gesichtsausdrücke, Tonfall oder Gestik anderer zu verstehen und die Gefühle anderer zu erkennen sowie ihre eigenen Gefühle auszudrücken. Das kann es herausfordernd machen, sich in sozialen Situationen zurechtzufinden."
      },
      {
        "descr": "Autismus ist angeboren und bleibt ein Leben lang bestehen, wobei bestimmte Symptome sich verändern oder auch abnehmen können. Ab einem Alter von zwei Jahren kann von auf die Altersgruppe spezialisierten Fachpersonen (Psychotherapeut:innen oder Fachärzt:innen für Psychiatrie und Psychotherapie) eine Autismusdiagnose gestellt werden. Hierfür wird eine Reihe von Untersuchungen durchgeführt. Diese sind sehr aufwändig und umfassen unter anderem Gespräche, Verhaltensbeobachtungen und Fragebögen. Obwohl die ersten Symptome im frühen Kindesalter auftreten, wird bei einigen Menschen erst sehr spät festgestellt, dass sie eine Autismus-Spektrum-Störung haben. Das kann verschiedene Gründe haben, zum Beispiel wenn autismusspezifische Symptome nicht als solche erkannt werden. Außerdem sind die meisten Gesundheitssysteme überlastet und die Wartezeiten auf einen Diagnostiktermin können sehr lang sein."
      }
    ]
  },
  "KIDSSMASS": {
    "title": "KIDS-SM-ASS",
    "question": "Was machen wir im Projekt KIDS-SM-ASS?",
    "introduction": "Ziel des KIDS-SM-ASS-Projekts ist die Entwicklung und Validierung eines Screeningtools, um die Differenzialdiagnose von Autismus-Spektrums-Störungen (ASS) und selektivem Mutismus (SM) im Kindesalter zu verbessern. Aktuell kommt es zu häufigen Fehldiagnosen und langen Wartezeiten. Durch die Sammlung diagnostischer Daten während eines Computerspiels und die KI-gestützte Analyse von Parametern wie Augenbewegungen, Mimik und Stimme kann dieser Umstand verbessert und damit betroffene Kinder schneller einer gezielten umfassenden Diagnostik und spezifischen Behandlung zugeführt werden.",
    "description": [
      {
        "title": "Projektbeschreibung",
        "descr": [
          "Ziel des KIDS-SM-ASS-Projekts ist die Entwicklung und Validierung eines Screeningtools, um die Differenzialdiagnose von Autismus-Spektrums-Störungen (ASS) und selektivem Mutismus (SM) im Kindesalter zu verbessern. Aktuell kommt es zu häufigen Fehldiagnosen und langen Wartezeiten. Durch die Sammlung diagnostischer Daten während eines Computerspiels und die KI-gestützte Analyse von Parametern wie Augenbewegungen, Mimik und Stimme kann dieser Umstand verbessert und damit betroffene Kinder schneller einer gezielten umfassenden Diagnostik und spezifischen Behandlung zugeführt werden."
        ],
        "image": ""
      },
      {
        "title": "Welches Problem wollen wir lösen?",
        "descr": [
          "Bei Untersuchungen beim Psychologen oder der Kinderärztin weisen sowohl Kinder mit Autismus als auch mit selektivem Mutismus eine große Überschneidung an Symptomatik auf. So können beide in Untersuchungssituationen etwa Auffälligkeiten wie mangelnde Reaktion auf Ansprache oder Vermeidung von Blickkontakt zeigen. Das macht es schwierig, zwischen den beiden zu unterscheiden. Entscheidend für die Unterscheidung ist einerseits die vertraute Umgebung des Kindes zu Hause. In diesem Umfeld sollten Kinder mit SM die mit ASS deckungsgleichen Symptome nicht zeigen. Andererseits liegen SM und ASS auch verschiedene Mechanismen zugrunde. "
        ],
        "image": ""
      },
      {
        "title": "Und wie wollen wir es lösen?",
        "descr": [
          "Unser Ansatz ist es deshalb, das Screening zum Kind nach Hause zu bringen.",
          "Betreut durch eine vertraute Person spielt das Kind ein kurzes Spiel auf einem Tablet. Währenddessen werden verschiedene Daten gesammelt, darunter Augenbewegungen, Mimik, Stimme und Reaktionszeiten. Die gesammelten Daten werden mithilfe künstlicher Intelligenz automatisch ausgewertet und ein Bericht an die behandelnde Fachkraft geschickt. Die erfassten Parameter erlauben Rückschlüsse auf bestimmte Merkmale. So unterscheiden sich Kinder mit Autismus und Angststörungen etwa untereinander und von anderen Gruppen in verschiedenen Stimmparametern und Blickbewegungsmustern. "
        ],
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/kidssmass.png"
      },

      {
        "title": "",
        "descr": [
          "Das Vorgehen bedeutet weniger Stress für das untersuchte Kind und soll Fachkräften helfen, nicht nur Zeit zu sparen, sondern auch die Informationen zu erhalten, die sie brauchen, um die besten Behandlungsentscheidungen zu treffen."
        ],
        "image": ""
      },
      {
        "title": "Und was sind unsere Ziele für das Projekt?",
        "descr": [
          "Wir haben bereits einen Prototyp für ein Tool zum Autismusscreening entwickelt. Dieser beinhaltet KI-Modelle zur Stimmanalyse und zum Erfassen der Blickbewegungen und Mimik. Im Rahmen unseres Projekts entwickeln wir diesen Prototypen weiter und testen ihn mit den Personen, die ihn nachher anwenden sollen: Kindern und ihren Eltern. Außerdem entwickeln wir ein Elterntraining, um die Anwendung zu Hause zu erleichtern. Schließlich führen wir eine klinische Studie durch. Hierbei wird die Datenerhebung einmal im Labor und mehrmals zu Hause von den Eltern durchgeführt. Zum Vergleich wird eine ausführliche psychologische Diagnostik durchgeführt, sodass Testgütekriterien berechnet werden können. Diese Studie ist Teil der SMASS-Studie zu Gemeinsamkeiten und Unterschieden zwischen Selektivem Mutismus und Autismus-Spektrum-Störung, die an der JLU durchgeführt wird."
        ],
        "image": ""
      }
    ],
    "financialSupportTitle": "Förderung",
    "financialSupportDescription": [
      {
        "descr": "KIDS-SM-ASS wird durch das Bundesministerium für Bildung und Forschung (BMBF) im Rahmen des DATIpilot-Programms als Innovationssprint gefördert. ",
        "url": "",
        "image":"https://mappy.s3.eu-central-1.amazonaws.com/images/descr.jpg"
      },
      {
        "descr": "Das Bundesministerium für Bildung und Forschung (BMBF) veröffentlichte im Juli 2023 die Förderrichtlinie DATIpilot mit zwei Modulen: Innovationssprints (Modul 1) und Innovationscommunities (Modul2). Der DATIpilot zielt darauf ab, Förderprozesse zu vereinfachen und zu beschleunigen. Zudem fungiert er als Experimentierraum sowie als Erfahrungs- und Ideenspeicher für die im Aufbau befindliche Deutsche Agentur für Transfer und Innovation (DATI).",
        "image": "",
        "url": ""
      },
      {
        "descr": "Insgesamt werden 300 Innovationssprints gefördert. Bei der DATIpilot Roadshow zwischen November 2023 und Februar 2024 stellten knapp 600 Einzelakteure bzw. Teams ihre Ideen in fünfminütigen Pitches vor. Bei jeder der 23 Veranstaltungen wählten die Teilnehmenden selbst, welche Projekte eine Förderung bekommen sollen. Auf diesem Weg schafften es bereits 153 Innovationssprints in die Förderung. Für alle anderen gab es eine zweite Chance beim Losverfahren, wo weitere 147 Projekte gezogen wurden.",
        "image": "",
        "url": ""
      },
      {
        "title": "",
        "descr": "Die Projekte adressieren unterschiedlichste Themenfelder: Schwerpunkte liegen in den Bereichen KI/Maschinelles Lernen, Medizintechnik/Pharmazeutik und Gesundheits- und Sozialwesen. Bei etwa 20 Prozent der Projekte geht es um Soziale Innovationen.",
        "image": "",
        "url": ""
      },
      {
        "descr": "Weitere Informationen sind hier zu finden",
        "image": "",
        "url": "www.bmbf.de/datipilot"
      },
      {
        "descr": "Übrigens wurden auch alle vorgestellten Projekten als Graphic Recordings festgehalten. Anja Riese hat dieses tolle Graphic Recording zum KIDS-SM-ASS-Pitch erstellt.",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/Förderung.png",
        "url": "www.bmbf.de/datipilot"
      }
    ]
  },
  "about": {
    "title": "Über uns",
    "question": "Wer sind wir?",
    "description": {
      "descr": "Im KIDS-SM-ASS-Projekt arbeiten WissenschaftlerInnen der Justus-Liebig-Universität (JLU) Gießen und der Firma Vitafluence.ai aus Kronberg zusammen. Von der Seite der JLU wird das Projekt von Prof. Dr. Christina Schwenck geleitet und bei Vitafluence.ai von Dr. Wamuyu Owotoki."
    },
    "ourTeam": [
      {
        "name": "Prof. Dr. Christina Schwenck",
        "title": "Justus-Liebig-Universität Gießen",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/christina.png",
        "descr": [
          "Christina Schwenck ist Professorin für Klinische Kinder- und Jugendpsychologie an der Universität Gießen und eine international anerkannte Expertin zu selektivem Mutismus. Sie studierte Psychologie an den Universitäten Würzburg und Madrid und promovierte in Entwicklungspsychologie an der Universität Würzburg. Sie arbeitete an den Abteilungen für Kinder- und Jugendpsychiatrie, Psychosomatik und Psychotherapie der Universitätskliniken Würzburg, München und Frankfurt. Sie ist ausgebildete Kinder- und Jugendlichenpsychotherapeutin (Verhaltenstherapie) und ausgebildete Supervisorin. Ihre Forschungsinteressen umfassen selektiven Mutismus, Verhaltensstörungen und Kinder psychisch kranker Eltern.",
          "Prof. Schwenck setzt sich bei IACAPAP, der Internationalen Vereinigung für Kinder- und Jugendpsychiatrie und verwandte Berufe (International Association for Child and Adolescent Psychiatry and Allied Professions) für die Stärkung der psychischen Gesundheit von Kindern weltweit ein. So war sie von 2018-2022 Generalsekretärin der IACAPAP und ist derzeit im IACAPAP-Vorstand als Koordinatorin des Helmut Remschmidt Forschungsseminars (HRRS) tätig.",
          "In ihrer Freizeit geht sie ihrer Leidenschaft für die Fotografie rund um den Globus nach."
        ]
      },
      {
        "name": "Alexandra Ntiso, M.Sc. ",
        "title": "Justus-Liebig-Universität Gießen",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/alexandra.png",
        "descr": [
          "Alexandra Ntiso arbeitet als wissenschaftliche Mitarbeiterin an der Professur für klinische Kinder- und Jugendpsychologie (unter der Leitung von Frau Prof. Dr. Schwenck) an der Justus-Liebig-Universität Gießen. Ihre Forschungsarbeit konzentriert sich auf die Gemeinsamkeiten und Unterschiede zwischen dem Selektiven Mutismus und der Autismus-Spektrum-Störung. Sie studierte Psychologie an der Universität Trier und der Technischen Universität Chemnitz und promoviert nun an der Justus-Liebig-Universität Gießen. In ihrer Freizeit liest sie gerne und genießt ausgedehnte Spaziergänge in der Natur."
        ]
      },
      {
        "name": "Dr. Wamuyu Owotoki",
        "title": "Vitafluence.ai",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/wamuyu.png",
        "descr": [
          "Dr. Wamuyu Owotoki ist Mitgründerin und wissenschaftliche Leiterin von Vitafluence.ai sowie Mitgründerin und Geschäftsführerin von iZola.life. Dort entwickelt sie Innovationen in den Bereichen Telemedizin, KI-basiertes Screening und KI-gestütze Methoden zur Arzneimittelentwicklung. Sie studierte Pharmazie in St. Petersburg und promovierte in Medizinischer Chemie in Hamburg. Zudem verfügt sie über 12 Jahre Erfahrung in der pharmazeutischen Industrie in den Bereichen Neurologie, Ophthalmologie und Onkologie. Neben ihrem Gründerinnenalltag ist sie auch ab und zu als 'Mama Taxi' unterwegs, engagiert sich ehrenamtlich im Förderverein, und liebt es, neue Nagellackfarben auszuprobieren."
        ]
      },
      {
        "name": "Anninka Enseroth, M.Sc.",
        "company": "Vitafluence.ai",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/anninka.png",
        "descr": [
          "Anninka Enseroth arbeitet als Psychologin bei Vitafluence.ai, wo sie KI-basierte Methoden zur Erkennung von Autismus und verwandter Störungen entwickelt und erforscht. Sie studierte Psychologie in Berlin, Kairo und Gießen und schloss ihren Master an der Justus-Liebig-Universität mit einem Forschungsprojekt zur Evaluation der stationären Behandlung von Traumafolgestörungen ab. Sie mag Hunde, Kardamom-Kaffee und Podcasts zur Geschichte des Mittelalters, die sie am liebsten im Fitnessstudio hört. "
        ]
      }
    ],

    "join": [
      {
        "title": "Wie kann ich mitmachen?",
        "question": "Wie kann ich mitmachen?",
        "joinDescription": "Wir freuen uns, dass Sie mitmachen möchten! Sie können teilnehmen, wenn Sie mindestens 18 Jahre alt sind und",
        "instructions": [
          {
            "descr": "Sie die sorgeberechtigten Eltern von einem Kind im Alter von 4-12 Jahren sind mit",
            "instructr": [
              "vorhandener (Verdachts-)Diagnose Selektiver Mutismus oder",
              "vorhandener (Verdachts-)Diagnose Autismus-Spektrum-Störung sind oder",
              "ohne eine psychische Erkrankung"
            ]
          }
        ]
      }
    ],
    "getInvolved": [
      {
        "title": "Es gibt mehrere Möglichkeiten, mitzumachen:",
        "instructions": [
          "1. SMASS Online-Teil",
          "2. Neurobee Beta-Tester:in werden",
          "3. SMASS Laborteil und Neurobee "
        ],
        "how": [
          {
            "title": "SMASS Online-Teil",
            "descr": [
              {
                "descr": "In unserer Studie untersuchen wir die Gemeinsamkeiten und Unterschiede von zwei psychischen Erkrankungen, Selektivem Mutismus und der Autismus-Spektrum-Störung, mit Hilfe von verschiedenen Fragebögen. Das ist besonders wichtig für die Diagnostik aber auch, wenn es darum geht, die betroffenen Kinder bestmöglich therapeutisch zu unterstützen. Wir möchten auch Eltern von Kindern ohne psychische Erkrankung befragen, um die Unterschiede zwischen Kindern mit Selektivem Mutismus, Autismus-Spektrum-Störung und Kindern ohne diese Erkrankungen festzustellen. Die Studie besteht aus sechs Fragebögen (ca. 40 Minuten), die online bearbeitet werden. Sie können nach der Teilnahme an einer Verlosung teilnehmen. Insgesamt verlosen wir 20 Wunschgutscheine über jeweils 20 €. Wir freuen uns auf Ihre Mithilfe!",
                "link": ""
              }
            ],
            "moreInfo": [
              {
                "descr": "Weitere Informationen finden Sie hier",
                "bold": "",
                "link": "Probandeninfo Eltern Fragebogenteil",
                "image": ""
              },
              {
                "descr": "Bitte wenden Sie sich bei Fragen gerne per E-Mail an:",
                "bold": "",
                "link": "StudieSMASS@psychol.uni-giessen.de",
                "image": ""
              },
              {
                "descr": "Über den OR-Code oder mit Klick auf den folgenden Link gelangen Sie zum ersten Teil der Studie. Vorab finden Sie noch detailliertere Information zum Hintergrund der Studie, Ablauf und Datenschutz:",
                "bold": "",
                "link": "https://www.soscisurvey.de/SMASS/",
                "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/qr.png"
              },
              {
                "descr": "Teilnehmende am SMASS Online-Teil suchen wir",
                "bold": "ab sofort.",
                "link": "",
                "image": ""
              },
              {
                "descr": "Bitte beachten Sie:",
                "bold": "Bitte beachten Sie: Nach der Teilnahme am Online-Teil können Sie natürlich auch am Laborteil und Neurobee teilnehmen, müssen es aber nicht.",
                "link": "",
                "image": ""
              }
            ]
          },
          {
            "title": "Neurobee Beta-Tester:in werden",
            "descr": [
              {
                "descr": "Bei der Entwicklung von Neurobee interessiert uns natürlich auch, ob das Spiel Kindern in dieser Altersgruppe Spaß macht und wie leicht es für Eltern durchzuführen ist. Daher suchen wir Beta-Tester:innen. Hierbei stellen wir Ihnen die Neurobee-App sowie ein Tablet zur Verfügung. Sie führen dann das Spiel zu Hause mit Ihrem Kind durch. Im Anschluss an das Spiel bitten wir Sie und Ihr Kind um Feedback zu verschiedenen Aspekten des Spiels, zum Beispiel zur Länge, dem Design, und der Durchführbarkeit. Beta-Tester:innen suchen wir in den Monaten Oktober und November 2024. Für weitere Informationen und zur Beta-Test-Registrierung klicken Sie bitte hier:",
                "link": ""
              }
            ],
            "moreInfo": []
          },
          {
            "title": "SMASS Laborteil und Neurobee",
            "descr": [
              {
                "descr": "Wenn Sie beim SMASS Online-Teil mitgemacht haben und aus dem Raum Gießen kommen, können Sie auch am zweiten Teil der Studie (Laborteil und Neurobee) teilnehmen. Im Laborteil werden Sie mit Ihrem Kind verschiedene Aufgaben durchführen.  Dazu gehören ein IQ-Test Interaktionsaufgaben (Spiele) mit Ihnen sowie Interaktionsaufgaben (Spiele) mit der Versuchsleitung. Anschließend wird Ihr Kind viermal (einmal im Labor und dreimal zuhause) ein diagnostisches Testverfahren namens Neurobee unter Ihrer Anleitung durchführen. Bei Neurobee handelt es sich um ein Computerspiel, während dessen diagnostische Daten wie Augenbewegungen, Mimik und Stimmfrequenzen gesammelt und analysiert werden. Die Ergebnisse sind besonders für die Diagnostik interessant, aber auch wichtig, wenn es darum geht, die betroffenen Kinder bestmöglich therapeutisch zu unterstützen. Für Ihre Teilnahme erhalten Sie einen 160€-Wunschgutschein und Ihr Kind ein kleines Geschenk. Weitere Informationen erhalten Sie hier: ",
                "link": "Probandeninfo Eltern Laborteil und Neurobee"
              },
              {
                "descr": "Teilnehmende am SMASS Laborteil mit Neurobee suchen wir",
                "bold": "ab Dezember 2024"
              }
            ],
            "moreInfo": []
          }
        ]
      }
    ]
  },
  "contact": {
    "title": "Contact Us",
    "contactUs": [
      {
        "question": "Was mache ich, wenn ich noch mehr Fragen habe?",
        "description": "Wenn Sie noch Fragen haben können Sie uns über dieses Kontaktformular oder",
        "contact": "StudieSMASS@psychol.uni-giessen.de erreichen"
      }
    ]
  },
  "faqs": {
    "title": "Häufig gestellte Fragen",
    "questions": [
      {
        "question": "Was ist Selektiver Mutismus?",
        "answer": "Selektiver Mutismus ist eine komplexe Angststörung, die die Fähigkeit einer Person beeinträchtigt, in bestimmten sozialen Situationen zu sprechen."
      },
      {
        "question": "Wie kann ich jemandem mit Selektivem Mutismus helfen?",
        "answer": "Jemandem mit Selektivem Mutismus zu helfen, bedeutet, eine unterstützende Umgebung zu schaffen und sie allmählich zu ermutigen, in einer komfortablen Weise zu sprechen."
      }
    ]
  },

  "study":  [
    "Neurobee Beta-Tester (Okt 2024 – Nov 2024)",
    "SMASS Online- und Laborstudie (Okt 2024 – Sep 2025)", 
    "Ich bin mir noch nicht sicher" 
    ],

    "join_study":{
      "phone_number": "Telefonnummer",
      "address": "E-Mail-Adresse",
      "select_study": "Wählen Sie die Studie, an der Sie interessiert sind",
      "name": "Vollständiger Name",
      "privacy_policy": "Ich habe die gelesen und stimme der",
      "policy": "Datenschutzrichtlinie zu",
      "description": "Gibt es noch etwas, das Sie uns mitteilen möchten? (Optional)",
      "joinInTitle": "Bitte füllen Sie das folgende Formular aus, um beizutreten",
      "join": "Beitreten",
      "policy_check_warning": "Bitte das Kästchen für die Datenschutzrichtlinie markieren",
      "input_warning": "Bitte füllen Sie die erforderlichen Felder aus",
      "error_submiting_email": "Beim Hinzufügen Ihrer E-Mail ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
      "email_sent": "E-Mail erfolgreich hinzugefügt!",
      "cancel": "Abbrechen",
      "joining": "Beitreten"
    },

    "impressum": {
      "title":"Impressum",
      "address": "Vitafluence.ai GmbH Westerbachstraße 23 61476 Kronberg",
      "contacts": [
        "Telefon: +49 61747838282",
        "E-Mail: connect@vitafluence.ai"
      ],
      "description":[
        {
          "title":"Vertreten durch:",
          "subtitle": "",
          "descr": "Geschäftsführer Dr. Peter O. Owotoki Registereintrag:"
          ,"link": ""
        },
        {
          "title":"Registereintrag:",
          "subtitle": "",
          "descr": "Eingetragen im Handelsregister. Registergericht: Amtsgericht Königstein im Taunus Registernummer: HRB 10044",
          "link": ""
        },
        {
          "title":"Umsatzsteuer-ID:",
          "subtitle": "",
          "descr": "Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: 043 240 96517",
          "link": ""
        },
        {
          "title":"Hinweis gemäß Online-Streitbeilegungs-Verordnung",
          "subtitle": "",
          "descr": "Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz der Europäischen Online-Streitbeilegungs-Plattform hinzuweisen, die für die Beilegung von Streitigkeiten genutzt werden kann, ohne dass ein Gericht eingeschaltet werden muss. Für die Einrichtung der Plattform ist die Europäische Kommission zuständig. Die Europäische Online-Streitbeilegungs-Plattform ist hier zu finden:",
          "link": "<a href='http://ec.europa.eu/odr'>http://ec.europa.eu/odr</a> Unsere E-Mail lautet: <a href='mailto:neurobee@vitafluence.ai> connect@vitafluence.ai</a>"
        },

        {
          "title":"",
          "subtitle": "",
          "descr": "Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen der Europäischen Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer."
        ,"link": ""
        },
        {
          "title":"Disclaimer – rechtliche Hinweise",
          "subtitle": "§ 1 Warnhinweis zu Inhalten",
          "descr": "Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters."
        ,"link": ""
        },
        {
          "title":"",
          "subtitle": "§ 2 Externe Links",
          "descr": "Diese Website enthält Verknüpfungen zu Websites Dritter ('externe Links'). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht."
        ,"link": ""
        },
        {
          "title":"",
          "subtitle": "§ 3 Urheber- und Leistungsschutzrechte",
          "descr": "Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt."
        ,"link": ""
        },
        {
          "title":"",
          "subtitle": "",
          "descr": "Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig."
        ,"link": ""
        },
        {
          "title":"",
          "subtitle": "§ 4 Besondere Nutzungsbedingungen",
          "descr": "Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen. Quelle:",
          "link":"<a styles='color: #4FBA64' href='https://www.juraforum.de/'>Hier klicken </a>"
        }
      ]
    },
    "privacy_policy": {
    "title":"Privacy policy",
    "description":[
      {
        "title":"Introduction",
        "subtitle": "",
        "descr": "Thank you for choosing to be part of our community at Vitafluence.ai. We are committed to protecting your personal information and right to privacy governed by the principles of General Data Protection Regulation (GDPR). If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at "
        ,"link": "<b>connect@vitafluence.ai</b>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "When you visit our website, use our mobile application or any of our services, we appreciate that you are trusting us with your personal information. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. Please take some time to read though it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please inform us immediately.",
        "link": ""
      },
      {
        "title":"What data do we collect?",
        "subtitle": "",
        "descr": "Vitafluence.ai collects personal data when you submit an inquiry through our contact form for the purpose of contacting you about participation in our research. Upon your request and expression of consent, your data might also be shared with third parties such as our research partners. It is removed upon your withdrawal of consent or your request to delete your data.",
        "link": "<ul style='margin-left: 30px'><li>Personal identification information (Name, email address, phone number etc.)</li><li>The content of your message</li></ul>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "If you leave a message on the Website, your name and email address will also be saved in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will be saved on your computer until you delete them",
        "link": "<ul style='margin-left: 30px'><li><b>IP and browser user agent string:</b> this data is collected when you leave a comment.</li> <li><b>Retention period:</b> the aforementioned data is retained indefinitely so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue. </li></ul>"
      },

      {
        "title":"How do we collect your data?",
        "subtitle": "",
        "descr": "You directly provide Vitafluence.ai with most of the data we collect. We collect data and process data when you:",
        "link": "<ul style='margin-left: 30px'><li>Send a message through our contact form</li><li>Vitafluence.ai may also receive your data indirectly from your browser as inputs to cookies.</li></ul>"
      },
      {
        "title":"How do we store your data?",
        "subtitle": "",
        "descr": "Vitafluence.ai securely stores your data at our cloud data storage facilities hosted in the cloud in region Frankfurt. We strictly adhere to the GDPR principle of storage limitation and data retention policies; therefore, we erase or anonymize any personal data that is no longer needed. A client also has the right to revoke access and request us to delete all their personal data at any given time."
      ,"link": ""
      },
      {
        "title":"Marketing",
        "subtitle": "",
        "descr": "Vitafluence.ai would like to send you information about products and services of ours that we think you might like, as well as those of our partners."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "If you have agreed to receive marketing, you may always opt out at a later date. You have the right at any time to stop Vitafluence.ai from contacting you for marketing purposes or giving your data to other members of the Vitafluence.ai Company. If you no longer wish to be contacted for marketing purposes, you will be able to make the changes in your profile on our website."
      ,"link": ""
      },
      {
        "title":"What are your data protection rights?",
        "subtitle": "",
        "descr": "Vitafluence.ai would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:."
      ,"link": "<ul style='margin-left: 30px'><li><b>The right to access</b> - You have the right to request Vitafluence.ai for copies of your personal data.</li><li><b>The right to rectification</b> - You have the right to request that Vitafluence.ai correct any information you believe is inaccurate. You also have the right to request Vitafluence.ai to complete the information you believe is incomplete.</li><li><b>The right to erasure </b>- You have the right to request that Vitafluence.ai erase your personal data, under certain conditions.</li><li><b>The right to restrict processing</b> - You have the right to request that Vitafluence.ai restrict the processing of your personal data, under certain conditions.</li><li><b>The right to object to processing</b> - You have the right to object to Vitafluence.ai’s processing of your personal data, under certain conditions.</li><li><b>The right to data portability</b> - You have the right to request that Vitafluence.ai transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li></ul>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email:",
        "link":"Call us at: <b style='color: #7ce0f9'>+49 61747838282</b>Or write to us: <b style='color: #7ce0f9'>connect@vitafluence.ai.</b>"
      },

      {
        "title":"Cookies",
        "subtitle": "",
        "descr": "Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology For further information, visit.",
        "link":"<a style='color: #7ce0f9' href='https://www.allaboutcookies.org/'> allaboutcookies.org</a>"
      },
      {
        "title":"How do we use cookies?",
        "subtitle": "",
        "descr": "Vitafluence.ai uses cookies in a range of ways to improve your experience on our website, including:",
        "link":"<ul style='margin-left: 30px'><li>Keeping you signed in</li><li>Understanding how you use our website.</li></ul>"
      },
      {
        "title":"What types of cookies do we use?",
        "subtitle": "",
        "descr": "There are a number of different types of cookies, however, our website uses:",
        "link":"<ul style='margin-left: 30px'><li><b>Functionality</b> - Vitafluence.ai uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</li><li><b>Advertising </b>Vitafluence.ai uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Vitafluence.ai sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</li></ul>"
      },

      {
        "title":"How to manage cookies.?",
        "subtitle": "",
        "descr": "You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.",
        "link":""
      },
      {
        "title":"Privacy policies of other websites.",
        "subtitle": "",
        "descr": "Our site may contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.",
        "link":""
      },
      {
        "title":"Changes to our privacy policy.?",
        "subtitle": "",
        "descr": "Vitafluence.ai keeps its privacy policy under regular review, any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy. This privacy policy was last updated on 08 October 2024.",
        "link":""
      },
      {
        "title":"How to contact us.",
        "subtitle": "",
        "descr": "If you have any questions about Vitafluence.ai’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.",
        "link":"<ul style='margin-left: 30px'><li>Call us: <b>+49 61747838282</b> </li><li>Or write to us at: <b>connect@vitafluence.ai.</b></li></ul>"

      },
      {
        "title":"How to contact the appropriate authority.",
        "subtitle": "",
        "descr": "Should you wish to report a complaint or if you feel that Vitafluence.ai has not addressed your concern in a satisfactory manner, you may contact the Data Commissioner’s Office.",
        "link":""
      }
    ]
  }
}
