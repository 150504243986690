import React, {useState} from "react";
import {
  Box,
  Flex,
  Button,
  HStack,
  Image,
  Link as ChakraLink,
  Menu,
  MenuList,
  MenuItem,
  useMediaQuery,
  MenuButton,
  IconButton,
  useColorModeValue,
  textDecoration,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useLocation,useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitch from './LanguageSwitch';
import { HamburgerIcon } from '@chakra-ui/icons';
import JoinCommunity from "./JoinCommunity";

const Navbar = () => {
  const { t } = useTranslation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const linkBackground = useColorModeValue('primary.200', 'secondary.200');
  const activeLinkStyle = {
    bg: linkBackground,
    bgGradient: "linear(to-b, primary.300, secondary.200)",
    fontWeight: "bold",
    // padding: 10,
    height: 16,
    justifyContent:'center',
    alignContent:'center',
    paddingRight: 10,
    paddingLeft: 10,
    textDecoration: "none"

  };

  const mobActiveLinkStyle = {
    bg: linkBackground,
    bgGradient: "linear(to-b, primary.300, secondary.200)",
    fontWeight: "bold",
    // padding: 10,
    height: 10,
    justifyContent:'center',
    alignContent:'center',
    paddingRight: 5,
    paddingLeft: 5,
    borderRadius: 3,
    textDecoration: "none"
  };

  const handleCloseModal = () => {
      setIsOpen(false);
  };

  const handleOpenJoinCommunity = () => {
      setIsOpen(true);
  };

  return (
    <Box px={4} position="sticky" top={0} zIndex={1000} bg={useColorModeValue("white", "gray.800")} boxShadow="md">
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <HStack>
          <Image src={t("navbar.logo", {returnObjects: true})[0]} alt="Vitafluence" width={isMobileView ? 100 : 150} />
          <Image src={t("navbar.logo", {returnObjects: true})[1]} alt="University" width={isMobileView ? 50 : 100} />
        </HStack>

        {!isMobileView && (
          <HStack>
            <HStack spacing={4}>
              <ChakraLink
                as={ReactRouterLink}
                to="/"
                {...(location.pathname === "/" ? activeLinkStyle : {})}
              >
                {t("navbar.home")}
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/kids-sm-ass"
                {...(location.pathname === "/kids-sm-ass" ? activeLinkStyle : {})}
              >
                {t("navbar.kidssmass")}
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/selective-mutism"
                {...(location.pathname === "/selective-mutism" ? activeLinkStyle : {})}
              >
                {t("navbar.selective_mutism")}
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/autism"
                {...(location.pathname === "/autism" ? activeLinkStyle : {})}
              >
                {t("navbar.autism")}
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/participate"
                {...(location.pathname === "/participate" ? activeLinkStyle : {})}
              >
                {t("navbar.participate")}
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/aboutus"
                {...(location.pathname === "/aboutus" ? activeLinkStyle : {})}
              >
                {t("navbar.aboutUs")}
              </ChakraLink>

              
              
            </HStack>
            <Button
              borderRadius={"full"}
              colorScheme="primary.300"
              variant="solid"
              color={'black'}
              ml={20}
              onClick={handleOpenJoinCommunity}
            >
              {t("navbar.joinIn")}
            </Button>
          </HStack>
        )}

        {isMobileView && (
          <Menu zIndex={"10000"} right={0}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList>
              <MenuItem>
                <ChakraLink
                  as={ReactRouterLink}
                  to="/"
                  color={"gray.600"}
                  {...(location.pathname === "/" ? mobActiveLinkStyle : {})}
                >
                  {t("navbar.home")}
                </ChakraLink>
              </MenuItem>
              <MenuItem>
                <ChakraLink
                  as={ReactRouterLink}
                  to="/kids-sm-ass"
                  color={"gray.600"}
                  {...(location.pathname === "/kids-sm-ass" ? mobActiveLinkStyle : {})}
                >
                  {t("navbar.kidssmass")}
                </ChakraLink>
              </MenuItem>
              <MenuItem>
                <ChakraLink
                  as={ReactRouterLink}
                  to="/selective-mutism"
                  color={"gray.600"}
                  {...(location.pathname === "/selective-mutism" ? mobActiveLinkStyle : {})}
                >
                  {t("navbar.selective_mutism")}
                </ChakraLink>
              </MenuItem>
              <MenuItem>
                <ChakraLink
                  as={ReactRouterLink}
                  to="/autism"
                  color={"gray.600"}
                  {...(location.pathname === "/autism" ? mobActiveLinkStyle : {})}
                >
                  {t("navbar.autism")}
                </ChakraLink>
              </MenuItem>
              <MenuItem>
                <ChakraLink
                  color={"gray.600"}
                  as={ReactRouterLink}
                  to="/participate"
                  {...(location.pathname === "/participate" ? mobActiveLinkStyle : {})}
                >
                  {t("navbar.participate")}
                </ChakraLink>
              </MenuItem>
              
              <MenuItem>
                <ChakraLink
                  color={"gray.600"}
                  as={ReactRouterLink}
                  to="/aboutus"
                  {...(location.pathname === "/aboutus" ? mobActiveLinkStyle : {})}
                >
                  {t("navbar.aboutUs")}
                </ChakraLink>
              </MenuItem>
  
              

              <MenuItem>
                <Button
                  borderRadius={"full"}
                  colorScheme="primary.300"
                  variant="solid"
                  size={"md"}
                  alignSelf={"left"}
                  color={'black'}
                  onClick={handleOpenJoinCommunity}
                >
                  {t("navbar.joinIn")}
                </Button>
              </MenuItem>
              <MenuItem>
                <LanguageSwitch />
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
      <JoinCommunity isOpen={isOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default Navbar;
