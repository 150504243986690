import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Heading,
  Image,
  Text,
  VStack,
  useTheme,
  UnorderedList,
  ListItem,
  OrderedList,
  Link,
  Card,
  CardHeader,
  CardBody,
  SimpleGrid,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";

const Content = ({ content, contact}) => {
  const { colors } = useTheme();
  const location = useLocation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  return (
    <Box
      textAlign="left"
      position="relative"
      width="100%"
      pt={5}
      backgroundColor={"transparent"}
      justifyContent={"center"}
    >
      <Box
        height="auto"
        width={{ base: "90%", md: "80%", lg: "80%" }}
        backgroundColor={"transparent"}
        justifyContent={"center"}
        margin={"0 auto"}
        pt={5}
      >
        {/* Title */}
        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content?.title || "About us"}
        </Heading>

        {/* Description */}
          <Text
            textAlign="left"
            fontSize="md"
            color="gray.700"
            mb={4}
          >
            {content.description.descr}
          </Text>
        <SimpleGrid
          columns={isMobileView ? 1 : 2}
          spacing={6}
          width={"100%"}
        >
          {isMobileView
            ? [content.ourTeam[0], content.ourTeam[2], content.ourTeam[1], content.ourTeam[3]].map((team, index) => (
              team ? ( // Ensure the team exists
                <Card
                  key={index}
                  direction={{ base: "column", sm: "row" }}
                  overflow="hidden"
                  variant="outline"
                  bgGradient="linear(to-t, white, secondary.100)"
                  mt={3}
                >
                  <Stack>
                    <CardBody>
                      <Box>
                        <Heading size="sm" color={"primary.300"}>
                          {team.name}
                        </Heading>
                        <Text fontWeight={"500"} mb={5}>
                          {team.title}
                        </Text>
                        <Image
                          objectFit="contain"
                          src={team.image}
                          alt="team"
                          width={100}
                          height={150}
                          p={2}
                        />
                      </Box>
                      <Text>{team.descr}</Text>
                    </CardBody>
                  </Stack>
                </Card>
              ) : null
            ))
            : content.ourTeam?.map((team, index) => (
              <Card
                key={index}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                bgGradient="linear(to-t, white, secondary.100)"
                mt={3}
              >
                <Stack>
                  <CardBody>
                    <Box>
                      <Heading size="sm" color={"primary.300"}>
                        {team.name}
                      </Heading>
                      <Text fontWeight={"500"} mb={5}>
                        {team.title}
                      </Text>
                      <Image
                        objectFit="contain"
                        src={team.image}
                        alt="team"
                        width={100}
                        height={150}
                        p={2}
                      />
                    </Box>
                    <Text mt={5}>{team.descr}</Text>
                  </CardBody>
                </Stack>
              </Card>
            ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Content;
