import React, { useEffect } from "react";
import {
    Box,
    Heading,
    Text,
    VStack,
    useTheme, Menu,
    Flex,
    useMediaQuery,
    AspectRatio
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Content = () => {
    const { t } = useTranslation();
    const [isMobileView] = useMediaQuery("(max-width: 991px)");

    return (
        <Box
            textAlign="center"
            position="relative"
            width={isMobileView ? "100%" : "80%"}
            mt={isMobileView ? 5 : 10}
            pt={isMobileView ? 5 : 10}
            opacity={1.0}
        >
            {/* <AspectRatio height={500} position={'relative'} > */}
                <iframe
                    style={{height:400, position:"relative", width: '100%'}}
                    title='selective mutism'
                    src='https://mappy.s3.eu-central-1.amazonaws.com/images/devideo.mp4'
                    allowFullScreen
                />
            {/* </AspectRatio> */}
        </Box>
    );
};

export default Content;
