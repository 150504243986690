export const en = {
  "navbar": {
    "home": "Home",
    "selective_mutism": "Selective Mutism",
    "autism": "Autism",
    "kidssmass": "KIDS-SM-ASS",
    "aboutUs": "About Us",
    "participate": "Participate",
    "contactUs": "Contact",
    "joinIn": "Join In",
    "logo": [
      "https://mappy.s3.eu-central-1.amazonaws.com/images/vitlogo.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/logopartner.png"
    ]
  },
  "home":{
    "welcomeMessage": "Welcome to the website of the KIDS-SM-ASS project",
    "welcomeMessageDescription": "AI-assisted differential diagnosis for selective mutism and autism spectrum disorder.",
    "homeDescription": "Welcome to the website of the KIDS-SM-ASS project! KIDS-SM-ASS stands for AI-assisted differential diagnosis in screening for selective mutism and autism spectrum disorder. You can find out what selective mutism (SM) and autism spectrum disorders (ASD) are and how artificial intelligence can support early detection here. On this page you can also find out how you can help us better support children with autism or selective mutism.",
    "banner_images":[
      "https://mappy.s3.eu-central-1.amazonaws.com/images/bg.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/child.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/partners.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/ground.png"
      ]
  },
  
  "selectiveMutism": {
    "title": "Selective Mutism",
    "question": "What is Selective Mutism?",
    "description": [
      {
        "descr": "Selective mutism affects children who re unable to speak in certain, predictable situations or to certain people, while speaking in other situations, and especially to trusted people, is possible without any problems."
      },
      {
        "descr": "Selective mutism' is n anxiety disorders and occurs in about 1% of all children, although the number of unreported cases is probably even higher. It is often mistakenly assumed that the child is 'just a little shy', and parents seeking advice are rejected with the words 'it will grow out'. While shy children in social situations usually begin to speak after a certain warm-up phase and become increasingly relaxed, the inability to speak of children with selective mutism has a high temporal stability."
      },
      {
        "descr": "The symptoms of selective mutistic children can manifest themselves in different forms. For example, some affected children show themselves to be 'frozen' in anxiety-inducing situations. Others, on the other hand, may only answer questions with facial expressions and gestures, with short, brief statements, a very low voice or in a whisper."
      },
      {
        "descr": "Selective mutism can cause significant disruption in the lives of children who are unable to behave the way they would like to due to their fear. This has an impact on school performance as well as on communication with peers or kindergarten teachers/teachers. To determine whether a child has selective mutism, a detailed psychological assessment is necessary."
      },
      {
        "descr": "Selective mutism is a disorder that usually begins between 2 and 5 years of age. According to recent studies, selective mutism is caused by a combination of several influencing factors, which can play a different role in different children. Some children tend to behave anxiously or reservedly even in infancy and react to new, unfamiliar stimuli with tension and avoidance. If a child shows such an anxious temperament, it increases the likelihood of selective mutism later on. Studies have shown that there is a genetic influence in the development of selective mutism. In addition, the presence of a migration background seems to be a risk factor for the development of this disorder. Above all, learning a new language and the associated insecurity when speaking plays a role here -"
      },
      {
        "descr": "In some cases, false assumptions about development factors are circulating, but these cannot be confirmed due to the current state of research: Selective mutism does not arise from a traumatic experience or traumatization of the child."
      },
      {
        "descr": "Selective mutism is usually not a reaction of defiance or refusal to speak. Rather, the affected children cannot express themselves because of their intense fear, although they would like to speak"
      }
    ]
  },
  "autism": {
    "title": "Autism",
    "question": "What is Autism?",
    "description": [
      {
        "descr": "Autism spectrum disorder is characterized by difficulties in communication and social interaction as well as behaviours that are characterized by recurring patterns and repetition. Autism is a developmental disorder that affects about one in a hundred people. However, autism can However, autism can present very differently. Some people with autism, for example, need more support in everyday life than others, and while some have very good language skills, others cannot speak or can only speak to a limited extent."
      },
      {
        "descr": "People with autism often have difficulty understanding the language, facial expressions, tone of voice, or gestures of others, and recognizing the feelings of others, as well as expressing their own feelings. This can make it challenging to find one's way in social situations,"
      },
      {
        "descr": "Autism is congenital and persists throughout life, although certain symptoms can change or decrease. From the age of two, an autism assessment can be conducted by professionals specializing in the age group (psychotherapists or specialists in psychiatry and psychotherapy). A series of studies will be carried out for this purpose. These are very complex and include conversations, behavioural observations and questionnaires. Although the first symptoms appear in early childhood, some people are not diagnosed with autism spectrum disorder until very late. There can be various reasons for this, for example, if autism-specific symptoms are not recognized as such. In addition, most health systems are overloaded and the waiting times for a diagnostic appointment can be very long."
      }
    ]
  },
  "KIDSSMASS": {
    "title": "KIDS-SM-ASS",
    "question": "What are we doing in the KIDS-SM-ASS project?",
    "introduction": "The KIDS-SM-ASS project aims to develop and validate a screening tool to improve the differential diagnosis of autism spectrum disorders (ASD) and selective mutism (SM) in childhood. Currently, there are frequent misdiagnoses and long waiting times. By collecting diagnostic data during a computer game and the AI-supported analysis of parameters such as eye movements, facial expressions and voice, this circumstance can be improved and thus affected children can be directed more quickly to targeted comprehensive diagnostics and specific treatment.",
    "description": [
      {
        "title": "What problem do we want to solve",
        "descr": [
          "We aim to address frequent misdiagnoses and long waiting times in diagnosing ASD and SM. By employing AI to analyze diagnostic data from a computer game, we can improve accuracy and speed in identifying these disorders, leading to more targeted diagnostics and treatment."
        ],
        "image": ""
      },

      {
        "title": "Our Approach",
        "descr": [
          "Our approach involves developing and validating a screening tool using AI to analyze data from a computer game. This tool will help in accurately and swiftly diagnosing ASD and SM, thus improving the efficiency of the diagnostic process.",
          "The approach means less stress for the child being examined and is intended to help professionals not only save time but also get the information they need to make the best treatment decisions."
        ],
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/kidssmass2.png"
      },

      {
        "title": "",
        "descr": [
          "Our approach is therefore to bring the screening to the child’s home."
        ],
        "image": ""
      },
     
      {
        "title": "Project Goals",
        "descr": [
          "Our goals include reducing misdiagnoses and waiting times, and providing more accurate and timely diagnostics and treatment for children with ASD and SM by utilizing AI-driven analysis of diagnostic data collected through a computer game."
        ],
        "image": ""
      }
    ],
    "financialSupportTitle": "Funding",
    "financialSupportDescription": [
      {
        "descr": "KIDS-SM-ASS is funded by the Federal Ministry of Education and Research (BMBF) as part of the DATIpilot program as an innovation sprint.",
        "image": "",
        "url": ""
      },
      {
        "descr": "In July 2023, the Federal Ministry of Education and Research (BMBF) published the DATIpilot funding guideline with two modules: Innovation Sprints (Module 1) and Innovation Communities (Module 2). The DATIpilot aims to simplify and accelerate funding processes. In addition, it functions as a space for experimentation as well as a repository of experience and ideas for the German Agency for Transfer and Innovation (DATI), which is currently being set up.",
        "image": "",
        "url": ""
      },
      {
        "descr": "A total of 300 innovation sprints were funded. At the DATIpilot Roadshow between November 2023 and February 2024, almost 600 individual actors or teams presented their ideas in five-minute pitches. At each of the 23 events, the participants themselves chose which projects should receive funding. In this way, 153 innovation sprints have already made it into funding. For everyone else, there was a second chance in the lottery process, where a further 147 projects were drawn.",
        "image": "",
        "url": ""
      },
      { 

        "title": "", 
        "descr": "The projects address a wide variety of topics: The focus is on the areas of AI/machine learning, medical technology/pharmaceuticals and health and social services. About 20 per cent of the projects are about social innovations.", 
        "image": "" 
  
      }, 
      {
        "descr": "More information can be found here",
        "image": "",
        "url": "www.bmbf.de/datipilot"
      },
      {
        "descr": "By the way, all the projects presented were also recorded as graphic recordings. Anja Riese created this great graphic recording for the KIDS-SM-ASS pitch.",
        "image": "",
        "url": ""
      }
    ]
  },
  "about": {
    "title": "About Us",
    "question": "Who Are We?",
    "description": {
        "descr": "n the KIDS-SM-ASS project, scientists from Justus Liebig University (JLU) Giessen and the company Vitafluence.ai from Kronberg are working together. On the JLU side, the project is led by Prof. Dr. Christina Schwenck and on the Vitafluence.ai side by Dr. Wamuyu Owotoki"
    },
    "ourTeam": [
      {
        "name": "Prof. Dr. Christina Schwenck",
        "title": "Justus-Liebig-Universität Gießen",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/christina.png",
        "descr": [
          "Christina Schwenck is a Professor of Clinical Child and Adolescent Psychology at the University of Giessen and an internationally recognized expert on selective mutism. She studied psychology at the Universities of Würzburg and Madrid and received her doctorate in developmental psychology from the University of Würzburg. She worked at in the departments of child and adolescent psychiatry, psychosomatics and psychotherapy at the University Hospitals of Würzburg, Munich and Frankfurt. She is a trained child and adolescent psychotherapist (cognitive behavioural therapy) and a trained supervisor. Her research interests include selective mutism, behavioural disorders, and children of mentally ill parents.",
          "Prof. Schwenck is committed to strengthening the mental health of children worldwide at IACAPAP, the International Association for Child and Adolescent Psychiatry and Allied Professions. For example, she was Secretary General of IACAPAP from 2018-2022 and is currently on the IACAPAP Board as coordinator of the Helmut Remschmidt Research Seminar (HRRS).",
          "In her free time, she pursues her passion for photography around the globe."
        ]
      },
      {
        "name": "Dr. Wamuyu Owotoki",
        "title": "Vitafluence.ai",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/wamuyu.png",
        "descr": [
          "Dr. Wamuyu Owotoki is co-founder and scientific director of Vitafluence.ai and co-founder and CEO of iZola.life. There, she develops innovations in telemedicine, AI-based screening and AI-supported methods for drug development. She studied pharmacy in St. Petersburg and received her doctorate in medicinal chemistry in Hamburg. She also has 12 years of experience in the pharmaceutical industry in the fields of neurology, ophthalmology and oncology. In addition to her everyday life as a founder, she is also on the road as a 'Mama Taxi' from time to time, volunteers and loves to try out new nail polish colours."
        ]
      },
      {
        "name": "Alexandra Ntiso, M.Sc.",
        "title": "Justus-Liebig-Universität Gießen",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/alexandra.png",
        "descr": [
          "Alexandra Ntiso works as a research assistant at the Chair of Clinical Child and Adolescent Psychology (under the direction of Prof. Dr. Schwenck) at the Justus Liebig University Giessen. Her research focuses on the similarities and differences between selective mutism and autism spectrum disorder. She studied psychology at the University of Trier and the Chemnitz University of Technology and is now doing her doctorate at the Justus Liebig University in Giessen. In her free time, she likes to read and enjoys long walks in nature."
        ]
      },
      
      {
        "name": "Anninka Enseroth, M.Sc.",
        "title": "Vitafluence.ai",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/anninka.png",
        "descr": [
          "Anninka Enseroth works as a psychologist at Vitafluence.ai, where she develops and researches AI-based methods for detecting autism and related disorders. She studied psychology in Berlin, Cairo and Giessen and completed her master's degree at Justus Liebig University with a research project on the evaluation of inpatient treatment of post-traumatic stress disorders. She likes dogs, cardamom coffee, and podcasts about the history of the Middle Ages, which she prefers to listen to in the gym."
        ]
      }
    ],
   
  },

  "participate": {
    "title":"How Can I Participate?",
    "join":[{
        "title": "",
        "question": "How Can I Participate?",
        "description": "We are happy that you want to participate! You can participate if you are at least 18 years old and:",
        "instructions": [
          {
            "descr": "You are , the custodial parent of a child aged 4-12 years with",
            "instructr": [
              "An existing (or suspected) diagnosis of selective mutism or",
              "An existing (or suspected) diagnosis of autism spectrum disorder or",
              "Without a mental illness"
            ]
          }
        ]
    }],
  
    "getInvolved": [
      {
        "title": "There are several ways to get involved:",
        "instructions": [
          "1. SMASS Online-Teil",
          "2. Neurobee Beta-Tester:in werden",
          "3. SMASS Laborteil und Neurobee "
        ],
        "how": [
          {
            "title": "SMASS Online-Teil",
            "descr": [
              {
                "descr": "In our study, we investigate the similarities and differences between two disorders, selective mutism and autism spectrum disorder, with the help of various questionnaires. This is particularly important for diagnostics, but also when it comes to providing the affected children with the best possible therapeutic support. We also want to interview parents of children without psychological disorders to determine the differences between children with selective mutism, autism spectrum disorder and children without these disorders. The study consists of six questionnaires (approx. 40 minutes) that can be filled online. You can take part in a raffle after participating. In total, we are giving away 20 vouchers of your choice for 20 € each. We look forward to your help!",
                "link": ""
              }
            ],
            "moreInfo": [
              {
                "descr": "More information can be found here",
                "bold": "Subject Info Parents Questionnaire Part",
                "link": "https://www.uni-giessen.de/de/fbz/fb06/psychologie/abt/psychologie/psychol/forschung/Fragebogenteil",
                "image": ""
              },
              {
                "descr": "If you have any questions, please feel free to contact us by e-mail:",
                "bold": "",
                "link": "StudieSMASS@psychol.uni-giessen.de",
                "image": ""
              },
              {
                "descr": "You can access the first part of the study via the OR code or by clicking on the following link. In advance, you will find even more detailed information on the background of the study, procedure and data protection:",
                "bold": "",
                "link": "https://www.soscisurvey.de/SMASS/",
                "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/qr.png"
              },
              {
                "descr": "We are looking for participants in the SMASS online part. ",
                "bold": "from now on.",
                "link": "",
                "image": ""
              },
              {
                "descr": "Please note: After participating in the online part, you can of course also participate in the laboratory part and Neurobee, but you don't have to.",
                "bold": "",
                "link": "",
                "image": ""
              }
            ]
          },
          {
            "title": "Neurobee Beta-Tester:in werden",
            "descr": [
              {
                "descr": "In the development of Neurobee, we are of course also interested in whether the game is fun for children in this age group and how easy it is for parents to perform. That's why we're looking for beta testers. We provide you with the Neurobee app and a tablet. You then perform the game at home with your child. After the game, we ask you and your child for feedback on various aspects of the game, such as length, design, and feasibility. We are looking for beta testers in October and November 2024. For more information and to register for the beta test, please email us at",
                "link":"<b>neurobee@vitafluence.ai.</b>"
              }
            ],
            "moreInfo": []
          },
          {
            "title": "SMASS laboratory section and Neurobee",
            "descr": [
              {
                "descr": "If you took part in the SMASS online part and come from the Giessen area, you can also participate in the second part of the study (laboratory part and Neurobee). In the laboratory part, you will perform various tasks with your child. These include an IQ test, interaction tasks (games) with you and interaction tasks (games) with the experimenter. Your child will then perform a diagnostic test called Neurobee four times (once in the lab and three times at home) under your guidance. Neurobee is a computer game during which diagnostic data such as eye movements, facial expressions and voice frequencies are collected and analyzed. The results are particularly interesting for diagnostics, but also important when it comes to providing the affected children with the best possible therapeutic support. For your participation, you will receive a 160€ voucher and your child will receive a small gift. Further information can be found here: ",
                "link": "Subject Info Parents Laboratory Part and Neurobee"
              },
              {
                "descr": "We are looking for participants in the SMASS laboratory part with Neurobee",
                "bold": "from December 2024"
              }
            ],
            "moreInfo": []
          }
        ]
      }
    ]
  },
  
  "contact": {
    "title": "Contact Us",
    "contactUs": [
      {
        "question": "What do I do if I have more questions?",
        "description": "If you have any questions, you can contact us via the contact form on this website or via email. ",
        "contact":"<ul style='margin-left: 30px'><li>Justus-Liebig Universität: <b>StudieSMASS@psychol.uni-giessen.de.</b></li> <li>Vitafluence.ai: <b>neurobee@vitafluence.ai</b> </li></ul>",
      }
    ]
  },
  "faqs": {
    "title": "Frequently Asked Questions",
    "questions": [
      {
        "question": "What is selective mutism?",
        "answer": "Selective mutism is a complex anxiety disorder that affects a person's ability to speak in certain social situations."
      },
      {
        "question": "How can I help someone with selective mutism?",
        "answer": "Helping someone with Selective Mutism means creating a supportive environment and gradually encouraging them to speak comfortably."
      }
    ]
  },
  "study":  [
    "Neurobee beta-testers (Oct 2024 – Nov 2024)",
    "SMASS online and laboratory study (Oct 2024 – Sep 2025)", 
    "I’m not sure yet" 
    ],
  "join_study":{
    "phone_number": "Phone number",
    "address": "Email address",
    "select_study": "Select study are you interested in",
    "name": "Full name",
    "nameLabel": "What is your full name?",
    "emailLabel": "What is your e-mail? ",
    "phoneLabel":"What is your phone number (optional)? ",
    "studyOptionLabel":"Which part of the study are you interested in? (multiple selections possible)",
    "descriptionLabel":"Anything else you want to let us know? ",
    "privacy_policy": "I have read and agree with the",
    "dePrivacyExt":"",
    "policy": "Privacy policy",
    "description":"Anything else you want to let us know? (Optional)",
    "joinInTitle": "Please fill in the form below to join",
    "join": "Join",
    "policy_check_warning":"Please check the privacy policy box",
    "input_warning": "Please fill in the required fields",
    "error_submiting_email":"There was an error adding your email. Please try again",
    "email_sent": "Request submitted successfully, check your email for more details!",
    "cancel": "Cancel",
    "joining": "Joining"
  },
  "impressum": {
    "title":"Impressum",
    "address": "Vitafluence.ai GmbH Westerbachstraße 23 61476 Kronberg",
    "contacts": [
      "Telefon: +49 15560676362",
      "E-Mail: neurobee@vitafluence.ai."
    ],
    "description":[
      {
        "title":"Vertreten durch:",
        "subtitle": "",
        "descr": "Geschäftsführer Dr. Peter O. Owotoki Registereintrag:"
        ,"link": ""
      },
      {
        "title":"Registereintrag:",
        "subtitle": "",
        "descr": "Eingetragen im Handelsregister. Registergericht: Amtsgericht Königstein im Taunus Registernummer: HRB 10044",
        "link": ""
      },
      {
        "title":"Umsatzsteuer-ID:",
        "subtitle": "",
        "descr": "Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: 043 240 96517",
        "link": ""
      },
      {
        "title":"Hinweis gemäß Online-Streitbeilegungs-Verordnung",
        "subtitle": "",
        "descr": "Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz der Europäischen Online-Streitbeilegungs-Plattform hinzuweisen, die für die Beilegung von Streitigkeiten genutzt werden kann, ohne dass ein Gericht eingeschaltet werden muss. Für die Einrichtung der Plattform ist die Europäische Kommission zuständig. Die Europäische Online-Streitbeilegungs-Plattform ist hier zu finden:",
        "link": "<a href='http://ec.europa.eu/odr'>http://ec.europa.eu/odr</a> Unsere E-Mail lautet: <a href='mailto:neurobee@vitafluence.ai>neurobee@vitafluence.ai.</a>"
      },

      {
        "title":"",
        "subtitle": "",
        "descr": "Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen der Europäischen Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer."
      ,"link": ""
      },
      {
        "title":"Disclaimer – rechtliche Hinweise",
        "subtitle": "§ 1 Warnhinweis zu Inhalten",
        "descr": "Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "§ 2 Externe Links",
        "descr": "Diese Website enthält Verknüpfungen zu Websites Dritter ('externe Links'). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "§ 3 Urheber- und Leistungsschutzrechte",
        "descr": "Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "§ 4 Besondere Nutzungsbedingungen",
        "descr": "Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen. Quelle:",
        "link":"<a styles='color: #4FBA64' href='https://www.juraforum.de/'>Hier klicken </a>"
      }
    ]
  },
  "privacy_policy": {
    "title":"Privacy policy",
    "description":[
      {
        "title":"Introduction",
        "subtitle": "",
        "descr": "Thank you for choosing to be part of our community at Vitafluence.ai. We are committed to protecting your personal information and right to privacy governed by the principles of General Data Protection Regulation (GDPR). If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at "
        ,"link": "<b> neurobee@vitafluence.ai.</b>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "When you visit our website, use our mobile application or any of our services, we appreciate that you are trusting us with your personal information. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. Please take some time to read though it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please inform us immediately.",
        "link": ""
      },
      {
        "title":"What data do we collect?",
        "subtitle": "",
        "descr": "Vitafluence.ai collects personal data when you submit an inquiry through our contact form for the purpose of contacting you about participation in our research. Upon your request and expression of consent, your data might also be shared with third parties such as our research partners. It is removed upon your withdrawal of consent or your request to delete your data.",
        "link": "<ul style='margin-left: 30px'><li>Personal identification information (Name, email address, phone number etc.)</li><li>The content of your message</li></ul>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "If you leave a message on the Website, your name and email address will also be saved in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will be saved on your computer until you delete them",
        "link": "<ul style='margin-left: 30px'><li><b>IP and browser user agent string:</b> this data is collected when you leave a comment.</li> <li><b>Retention period:</b> the aforementioned data is retained indefinitely so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue. </li></ul>"
      },

      {
        "title":"How do we collect your data?",
        "subtitle": "",
        "descr": "You directly provide Vitafluence.ai with most of the data we collect. We collect data and process data when you:",
        "link": "<ul style='margin-left: 30px'><li>Send a message through our contact form</li><li>Vitafluence.ai may also receive your data indirectly from your browser as inputs to cookies.</li></ul>"
      },
      {
        "title":"How do we store your data?",
        "subtitle": "",
        "descr": "itafluence.ai securely stores your data at our cloud data storage facilities hosted in the cloud in region Frankfurt. We strictly adhere to the GDPR principle of storage limitation and data retention policies; therefore, we erase or anonymize any personal data that is no longer needed. A client also has the right to revoke access and request us to delete all their personal data at any given time."
      ,"link": ""
      },
      {
        "title":"Marketing",
        "subtitle": "",
        "descr": "Vitafluence.ai would like to send you information about products and services of ours that we think you might like, as well as those of our partners."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "If you have agreed to receive marketing, you may always opt out at a later date. You have the right at any time to stop Vitafluence.ai from contacting you for marketing purposes or giving your data to other members of the Vitafluence.ai Company. If you no longer wish to be contacted for marketing purposes, you will be able to make the changes in your profile on our website."
      ,"link": ""
      },
      {
        "title":"What are your data protection rights?",
        "subtitle": "",
        "descr": "Vitafluence.ai would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:."
      ,"link": "<ul style='margin-left: 30px'><li><b>The right to access</b> - You have the right to request Vitafluence.ai for copies of your personal data.</li><li><b>The right to rectification</b> - You have the right to request that Vitafluence.ai correct any information you believe is inaccurate. You also have the right to request Vitafluence.ai to complete the information you believe is incomplete.</li><li><b>The right to erasure </b>- You have the right to request that Vitafluence.ai erase your personal data, under certain conditions.</li><li><b>The right to rectification</b> - You have the right to request that Vitafluence.ai correct any information you believe is inaccurate. You also have the right to request Vitafluence.ai to complete the information you believe is incomplete.</li><li><b>The right to erasure </b> - You have the right to request that Vitafluence.ai erase your personal data, under certain conditions. </li><li><b>The right to restrict processing </b> - You have the right to request that Vitafluence.ai restrict the processing of your personal data, under certain conditions.       </li><li><b>The right to object to processing </b> - You have the right to object to Vitafluence.ai’s processing of your personal data, under certain conditions. </li><li><b>The right to data portability </b> - You have the right to request that Vitafluence.ai transfer the data that we have collected to another organization, or directly to you, under certain conditions. </li></ul>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email:",
        "link":"<b style='color: #7ce0f9'>neurobee@vitafluence.ai.</b>"
      },

      {
        "title":"Cookies",
        "subtitle": "",
        "descr": "Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology For further information, visit.",
        "link":"<a style='color: #7ce0f9' href='https://www.allaboutcookies.org/'> allaboutcookies.org</a>"
      },
      {
        "title":"How do we use cookies?",
        "subtitle": "",
        "descr": "Vitafluence.ai uses cookies in a range of ways to improve your experience on our website, including:",
        "link":"<ul style='margin-left: 30px'><li>Understanding how you use our website.</li></ul>"
      },
      {
        "title":"What types of cookies do we use?",
        "subtitle": "",
        "descr": "There are a number of different types of cookies, however, our website uses:",
        "link":"<ul style='margin-left: 30px'><li><b>Functionality</b> - Vitafluence.ai uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</li><li><b>User Behaviour  </b>Vitafluence.ai uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address.</li></ul>"
      },

      {
        "title":"How to manage cookies.?",
        "subtitle": "",
        "descr": "You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.",
        "link":""
      },
      {
        "title":"Privacy policies of other websites.",
        "subtitle": "",
        "descr": "Our site may contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.",
        "link":""
      },
      {
        "title":"Changes to our privacy policy.?",
        "subtitle": "",
        "descr": "Vitafluence.ai keeps its privacy policy under regular review, any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy. This privacy policy was last updated on 22 October 2024.",
        "link":""
      },
      {
        "title":"How to contact us.",
        "subtitle": "",
        "descr": "If you have any questions about Vitafluence.ai’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.",
        "link":"<ul style='margin-left: 30px'><li>Call us: <b>+49 15560676362</b> </li><li>Or write to us at: <b>neurobee@vitafluence.ai.</b></li></ul>"

      },
      {
        "title":"How to contact the appropriate authority.",
        "subtitle": "",
        "descr": "Should you wish to report a complaint or if you feel that Vitafluence.ai has not addressed your concern in a satisfactory manner, you may contact the Data Commissioner’s Office.",
        "link":"<p style='margin-top:20px'>Der Hessische Beauftragte für Datenschutz und Informationsfreiheit <br/>Postfach 3163, 65021 Wiesbaden <br/>poststelle@datenschutz.hessen.de <br/> Telefon: <b>0611-140 80</b></p>"
      }
    ]
  }

}
